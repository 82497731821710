<template>
    <div>
      <Movementmenu/>
      <v-container>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ข้อมูลอัตราขาด/เกิน ประกอบการพิจารณาย้าย"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" md="8" class="text-right">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />
              </v-col>            
              <v-col cols="12" md="4" class="text-right">    
                <v-btn
                  block
                  right
                  depressed
                  color="primary"
                  @click.native="man_powersummaryCSV()"
                >
                  <v-icon>mdi-file-import</v-icon>นำเข้าข้อมูลอัตรากำลังขาด/เกิน
                </v-btn>
  
                <v-btn
                  block
                  right
                  depressed
                  color="red"
                  @click.native="man_powersummaryClear()"
                >
                  <v-icon>mdi-delete-sweep</v-icon>ล้างข้อมูล
                </v-btn>
               </v-col>
            </v-row>
          </v-card>
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="man_powersummarys"
            :search="search"
          >
            <template v-slot:item.status_booking="{ item }">
              <v-chip dark color="warning" v-if="item.status_booking">
                {{ item.status_booking }}
              </v-chip>
              <v-chip dark color="green" v-else>
                ว่าง
              </v-chip>
            </template>
  
            <template v-slot:item.actions="{ item }">
              <v-icon color="yellow" @click.stop="man_powersummaryEdit(item.id_no)">
                mdi-pencil
              </v-icon>
            </template>       
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
 
  
   
  
        <!-- V-model editman_powersummarydialog -->
        <v-layout row justify-center>
          <v-dialog v-model="editman_powersummarydialog" persistent max-width="80%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขข้อมูล"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editman_powersummaryform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>   
                    
                        <v-flex md6>
                          <v-autocomplete
                            v-model="editman_powersummary.summary_s"
                            :items="summaryText"
                            item-text="text"
                            item-value="value"
                            label="สรุป"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>           
                      <v-flex md6>
                        <v-text-field
                          label="จำนวน"
                          type="number"
                          v-model="editman_powersummary.total_s"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>                
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="editman_powersummarydialog = false" rounded>
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editman_powersummarySubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model ClearMan_powerdialog -->
        <v-layout>
          <v-dialog v-model="ClearMan_powerdialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ล้างข้อมูลอัตราขาด/เกิน พิจารณาย้าย"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="ClearMan_powerform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12> ยืนยันการล้างข้อมูล <br /> </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="ClearMan_powerdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="ClearMan_powerSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ยืนยันการล้างข้อมูล
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model man_powersummaryCSVdialog -->
        <v-layout>
          <v-dialog v-model="man_powersummaryCSVdialog" persistent max-width="50%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="green"
                icon="mdi-content-paste"
                title="นำเข้าข้อมูลอัตราขาด/เกิน พิจารณาย้าย"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-card>
                  <v-card-text>
                    <v-form ref="man_powersummaryCSVdialogform" lazy-validation>
                      <v-container grid-list-md>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-btn
                              block
                              large
                              color="info"
                              to="/admin/man_powersummary_import/"
                              target="_blank"
                            >
                              <v-icon>mdi-exit-to-app</v-icon
                              >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                            >
                          </v-col>
                          <v-col cols="12" md="12">
                            <h2>
                              <v-file-input
                                v-model="file2"
                                accept=".csv"
                                name="csvFiles"
                                color="deep-purple accent-4"
                                counter
                                label="ไฟล์ .csv"
                                placeholder="ไฟล์ CSV อัตรากำลัง"
                                prepend-icon="mdi-paperclip"
                                outlined
                                rounded
                                :show-size="1000"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    v-if="index < 2"
                                    color="deep-purple accent-4"
                                    dark
                                    label
                                    small
                                  >
                                    {{ text }}
                                  </v-chip>
  
                                  <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2"
                                  >
                                    +{{ files.length - 2 }} File(s)
                                  </span>
                                </template>
                              </v-file-input>
                            </h2>
  
                            <v-btn
                              class="pa-2"
                              block
                              large
                              color="green"
                              @click.stop="csvManpowerSubmit()"
                            >
                              <v-icon>mdi-book-plus</v-icon>
                              นำเข้า</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    large
                    color="grey"
                    @click.stop="man_powersummaryCSVdialog = false"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
import Movementmenu from '../../components/admin/movementmenu.vue';

  export default {
    data() {
        return {
            loading: true,
            ApiKey: "HRvec2021",
            valid: true,
            file2: null,
            addman_powersummarydialog: false,
            editman_powersummarydialog: false,
            deleteman_powersummarydialog: false,
            ClearMan_powerdialog: false,
            man_powersummaryCSVdialog: false,
            snackbar: {
                show: false,
                color: "",
                timeout: 5000,
                icon: "",
                text: ""
            },
            man_powersummarys: [],
            addman_powersummary: {},
            editman_powersummary: {},
            colleges: [],
            userstatus: [],
            search: "",
            pagination: {},
            headers: [
                { text: "สถานศึกษา", align: "left", value: "college_name" },
                { text: "จังหวัด", align: "left", value: "province_name" },
                { text: "สรุป", align: "left", value: "summary_s" },
                { text: "ขาด/เกิน", align: "left", value: "total_s" },
                {
                    text: "แก้ไข",
                    align: "center",
                    value: "actions",
                    icon: "mdi-file-document-edit"
                },
            ],
            rowsperpage: [
                25,
                50,
                100,
                {
                    text: "All",
                    value: -1
                }
            ],
            college: {},
            provinces: [],
            summaryText: [
                { text: 'เกินเกณฑ์', value: 'เกินเกณฑ์' },
                { text: 'ขาดเกณฑ์', value: 'ขาดเกณฑ์' },
                { text: 'พอดี', value: 'พอดี' },
            ],
            prefectures: [],
            collgegs: [],
            man_powersummarystatus: [],
            regions: [],
            region_ena: true,
            data_syslog: {}
        };
    },
    async mounted() {
        await this.man_powersummaryQueryAll();
        let result;
        result = await this.$http.post("college.php", {
            ApiKey: this.ApiKey
        });
        this.colleges = result.data;
        result = await this.$http.post("province.php", {
            ApiKey: this.ApiKey
        });
        this.provinces = result.data;
        result = await this.$http.post("region.php", {
            ApiKey: this.ApiKey
        });
        this.regions = result.data;
        let user_status_result;
        user_status_result = await this.$http.post("crud_user_status.php?crud=read", {
            ApiKey: this.ApiKey
        });
        this.userstatus = user_status_result.data;
    },
    methods: {
        async csvManpowerSubmit() {
            let result = "";
            let uploaded = null;
            if (this.file2) {
                let formData = new FormData();
                let filename = this.time_stamp + ".manpower.csv";
                formData.append("file", this.file2);
                formData.append("filename", "../HRvecfiles/" + filename);
                formData.append("ApiKey", this.ApiKey);
                result = await this.$http.post("man_powersummarycsv.php", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                uploaded = true;
            }
            else {
                uploaded = false;
            }
            if (result.data.status == true) {
                Swal.fire({
                    icon: "success",
                    title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.man_powersummaryQueryAll();
            }
            else {
                Swal.fire({
                    icon: "warning",
                    title: "ดำเนินการผิดพลาด",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.man_powersummaryQueryAll();
            }
            this.man_powersummaryCSVdialog = false;
        },
        async man_powersummaryCSV() {
            this.man_powersummaryCSVdialog = true;
        },
        async man_powersummaryClear() {
            this.ClearMan_powerdialog = true;
        },
        async sweetAlertLoading() {
            let timerInterval;
            Swal.fire({
                title: "Auto close alert!",
                html: "I will close in <b></b> milliseconds.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then(result => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    
                }
            });
        },
        async man_powersummaryReplace() {
            this.loading = true;
            let result = await this.$http
                .post("man_powersummary.php", {
                ApiKey: this.ApiKey,
                man_powersummaryCheck: "Ok"
            })
                .finally(() => (this.loading = false));
            this.man_powersummarys = result.data;
        },
        async man_powersummaryQueryAll() {
            this.loading = true;
            let result = await this.$http
                .post("man_powersummary.php", {
                ApiKey: this.ApiKey
            })
                .finally(() => (this.loading = false));
            this.man_powersummarys = result.data;
        },
        async man_powersummaryAdd() {
            this.addman_powersummary = {};
            this.addman_powersummarydialog = true;
        },
        async addman_powersummarySubmit() {
            if (this.$refs.addman_powersummaryform.validate()) {
                this.addman_powersummary.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_powersummary.insert.php", this.addman_powersummary);
                if (result.data.status == true) {
                    this.man_powersummary = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "insert";
                    this.data_syslog.page_log = "man_powersummary";
                    this.data_syslog.table_log = "man_powersummary";
                    this.data_syslog.detail_log =
                        this.addman_powersummary.id_summary_s +
                            ":" +
                            this.addman_powersummary.college_code;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_powersummaryQueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "บันทึกข้อมูลผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.man_powersummaryQueryAll();
                }
                this.addman_powersummarydialog = false;
            }
        },
        async man_powersummaryEdit(id_no) {
            let result = await this.$http.post("man_powersummary.php", {
                ApiKey: this.ApiKey,
                id_no: id_no
            });
            this.editman_powersummary = result.data;
            this.editman_powersummarydialog = true;
        },
        async editman_powersummarySubmit() {
            if (this.$refs.editman_powersummaryform.validate()) {
                this.editman_powersummary.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_powersummary.update.php", this.editman_powersummary);
                if (result.data.status == true) {
                    this.man_powersummary = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "update";
                    this.data_syslog.page_log = "man_powersummary";
                    this.data_syslog.table_log = "man_powersummary";
                    this.data_syslog.detail_log =
                        this.editman_powersummary.id_summary_s +
                            " : " +
                            this.editman_powersummary.college_code +
                            " : " +
                            this.editman_powersummary.case_vacancy;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_powersummaryQueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.editman_powersummarydialog = false;
            }
        },
        async man_powersummaryDelete(id_no) {
            let result = await this.$http.post("man_powersummary.php", {
                ApiKey: this.ApiKey,
                id_no: id_no
            });
            this.editman_powersummary = result.data;
            this.deleteman_powersummarydialog = true;
        },
        async deleteman_powersummarySubmit() {
            if (this.$refs.deleteman_powersummaryform.validate()) {
                this.editman_powersummary.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_powersummary.delete.php", this.editman_powersummary);
                if (result.data.status == true) {
                    this.man_powersummary = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "delete";
                    this.data_syslog.page_log = "man_powersummary";
                    this.data_syslog.table_log = "man_powersummary";
                    this.data_syslog.detail_log =
                        this.editman_powersummary.id_summary_s +
                            " : " +
                            this.editman_powersummary.college_code +
                            " : " +
                            this.editman_powersummary.case_vacancy;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_powersummaryQueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.deleteman_powersummarydialog = false;
            }
        },
        async ClearMan_powerSubmit() {
            if (this.$refs.ClearMan_powerform.validate()) {
                this.editman_powersummary.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_powersummary.clear.php", this.editman_powersummary);
                if (result.data.status == true) {
                    this.man_powersummary = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "Clear";
                    this.data_syslog.page_log = "man_powersummary";
                    this.data_syslog.table_log = "man_powersummary";
                    this.data_syslog.detail_log =
                        this.editman_powersummary.id_summary_s +
                            " : " +
                            this.editman_powersummary.college_code +
                            " : " +
                            this.editman_powersummary.case_vacancy;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_powersummaryQueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.ClearMan_powerdialog = false;
            }
        }
    },
    computed: {
        pages() {
            if (this.pagination.rowsPerPage == null ||
                this.pagination.totalItems == null)
                return 0;
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
        },
        date_today_log() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear() + 543;
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            today = dd + "/" + mm + "/" + yyyy + "/" + time;
            return today;
        }
    },
    components: { Movementmenu }
};
  </script>
  